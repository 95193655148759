import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../../components/layout'
import Seo from '../../components/plumbing/Head'
export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark
  return (
    <>
      <Layout>
        <Seo title={frontmatter.title} description="" />
        <section
          className="ten-central"
          style={{ marginTop: '9rem', display: 'block', height: '100%', marginBottom: '3rem', zIndex: 2 }}
        >
          <h1>{frontmatter.title}</h1>
          <h6>Updated {frontmatter.date}</h6>
          <div className="md-content" dangerouslySetInnerHTML={{ __html: html }} />
        </section>
      </Layout>
    </>
  )
}
export const pageQuery = graphql`
  query ($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        date(formatString: "YYYY-MM-DD")
        slug
        title
      }
    }
  }
`
